<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">选择结算单</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">确定</el-button>
        <div class="erp-default__btn" @click="back">取消</div>
      </div>
    </div>
    <el-radio-group v-model="checkId" style="width: 100%" class="no-label erp-radio">
      <el-table :data="formData" border class="egrid erp-table__list erp-view__table">
        <el-table-column label="" align="center" width="40" class-name="no-pd">
          <template #default="scope">
            <el-radio :label="scope.$index" @click="changeBox(scope.row)" :disabled="scope.row.isPayedAmountBiggerThenApPickupAmount">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="结算单号" class-name="no-pd" align="center" width="140">
          <template #default="scope">
            <div>{{ filterName(scope.row.apBuyStmtNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="提货款" class-name="no-pd" align="center" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.apPickupAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" class-name="no-pd" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="凭据文件" prop="fileUrl" align="center" class-name="no-pd" width="180">
          <template #default="scope">
            <div class="fileUrl-box">
              <tableFileContent
                :rowIndex="scope.$index"
                :fileUrlsArray="scope.row.fileVoList"
                key-name="orderArapFileName"
                key-url="orderArapFileUrl"
                key-id="orderArapFileId"
                :readOnly="true"
              ></tableFileContent>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" class-name="no-pd" width="180">
          <template #default="scope">
            <div>{{ filterName(scope.row.createdTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="varietyName" align="center" class-name="no-pd" width="140">
          <template #default="scope">
            <div>{{ filterName(scope.row.creator) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-radio-group>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
export default {
  components: { tableFileContent },
  props: {
    orderId: {
      type: [String, Number],
    },
    rowIndex: {
      type: [Number],
    },
  },
  data() {
    return {
      checkId: null,
      formData: [],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    changeBox(row) {
      if (row.isPayedAmountBiggerThenApPickupAmount === true) {
        this.errorTip('该费用已申请付款，请勿重复申请。');
      }
    },
    initialize() {
      const orderId = this.orderId;
      this.ajax({
        type: 'POST',
        url: 'malicrm/payment/getPickupAmountByOrderId',
        data: { orderId },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    viewFile(row) {
      this.$showFiles(row.orderArapFileName, row.orderArapFileUrl);
    },
    /**
     * 提交事件
     */
    submit() {
      if (this.checkId === null) {
        this.errorTip('请选择一条费用');
      } else {
        const data = this.formData.filter((v, index) => index === this.checkId)[0];
        this.$emit('checked', data, this.rowIndex);
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.link-color {
  color: #2878ff;
  font-size: 14px;
  cursor: pointer;
}
</style>
