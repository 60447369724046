<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">选择付款细目</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">确定</el-button>
        <div class="erp-default__btn" @click="back">取消</div>
      </div>
    </div>
    <el-radio-group v-model="checkId" style="width: 100%" class="no-label erp-radio">
      <el-table :data="formData" border class="egrid erp-table__list erp-view__table">
        <el-table-column label="" align="center" width="40" class-name="no-pd">
          <template #default="scope">
            <el-radio :label="scope.$index" :disabled="scope.row.isPayedAmountBiggerThenAmount" @click="changeBox(scope.row)">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="费用类型" class-name="no-pd" align="center" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.feeTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="金额(人民币)" class-name="no-pd" align="center" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.amountRmbDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" class-name="no-pd" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" class-name="no-pd" width="180" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.applyTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="applyOperator" align="center" class-name="no-pd" width="140" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.applyOperator) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-radio-group>
  </div>
</template>
<script>
import { store } from '@/store';
export default {
  components: {},
  props: {
    orderId: {
      type: [String, Number],
    },
    paymentType: {
      type: [String, Number],
    },
    rowIndex: {
      type: [Number],
    },
  },
  data() {
    return {
      checkId: null,
      formData: [],

      filterOption: { ap_fee_type: store.state.dict.options.ap_fee_type },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    changeBox(row) {
      if (row.isPayedAmountBiggerThenAmount === true) {
        this.errorTip('该费用已申请付款，请勿重复申请');
      }
    },
    initialize() {
      const orderId = this.orderId;
      const type = 2;
      let url = null;
      if (this.paymentType === 3) {
        url = 'malicrm/orderArap/queryArapFee';
      }
      if (this.paymentType === 5) {
        url = 'malicrm/orderArap/queryArapTaxFee';
      }
      this.ajax({
        type: 'POST',
        url: url,
        data: { orderId, type },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     * 提交事件
     */
    submit() {
      if (this.checkId === null) {
        this.errorTip('请选择一条费用');
      } else {
        const data = this.formData.filter((v, index) => index === this.checkId)[0];
        this.$emit('checked', data, this.rowIndex);
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
