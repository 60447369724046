<template>
  <div class="financical-form">
    <!--step1-->
    <div v-show="showType === 1">
      <div class="erp-form__header header-bar__sticky op-16">
        <div class="erp-form__title">{{formType === 1 ?'申请付款':'编辑付款'}}</div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="nextStep">下一步</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="erp-form__body" style="overflow: hidden">
        <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
          <div class="mali-edit__form">
            <el-col :span="12">
              <el-form-item label="收款方类型" prop="payeeType">
                <el-select v-model="formData.payeeType" @change="changePayeeType" class="mali-full__input" placeholder="请选择收款方类型">
                  <el-option :key="item.value + 'payeeType'" :label="item.label" :value="item.value" v-for="item in filterOption.payee_type_extend"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </div>
      <div class="financial-form-title op-16">
        <div class="erp-form__title f16" style="height: 16px">付款订单</div>
        <div class="title-tips">
          <span class="el-icon-warning"></span>
          提示：如申请批量付款，请注意收款方必须相同
        </div>
      </div>
      <div class="claim_company">
        <el-table
          :data="formData.paymentOrderDtoList"
          show-overflow-tooltip
          border
          class="egrid erp-table__list erp-view__table"
          :show-summary="showOrderListSummary"
          :summary-method="getSummaries"
        >
          <el-table-column align="center" class-name="no-pd table-visble required-icon" width="190">
            <template #header>
              <span>
                销售合同号
                <span class="icon-must">*</span>
              </span>
            </template>
            <template #default="scope">
              <div>
                <el-select
                  v-model="scope.row.businessContractNo"
                  clearable
                  filterable
                  remote
                  @change="contractNoChange($event, scope.$index)"
                  :remote-method="getContractNoOption"
                  @blur="blurContractNoOption(scope.$index)"
                  @focus="focusContractNoOption(scope.$index)"
                  placeholder
                >
                  <el-option
                    :key="item.id"
                    v-for="item in scope.row.ContractNoOption"
                    :label="item.businessContractNo"
                    :value="item.businessContractNo"
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="签约主体" prop="principalName" class-name="no-pd" align="center" show-overflow-tooltip width="110"> </el-table-column>
          <el-table-column align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #header>
              <span>
                付款类型
                <span class="icon-must">*</span>
              </span>
            </template>
            <template #default="scope">
              <el-select v-if="scope.row.businessContractNo" v-model="scope.row.paymentType" placeholder @change="changePayType(scope.row, scope.$index)">
                <el-option :key="index + 'payment'" v-for="(item, index) in filterOption.payment_type" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="no-pd" show-overflow-tooltip width="140">
            <template #header>
              <span>
                结算单号
                <span class="icon-must">*</span>
              </span>
            </template>
            <template #default="scope">
              <el-input
                clearable
                v-model="scope.row.buyStmtNo"
                placeholder
                :maxlength="8"
                v-if="scope.row.paymentType === 2 && scope.row.isNeedJr === true"
                readonly
                @click="openSettlementDialog(scope.row, scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #header>
              <span>
                付款细目
                <span class="icon-must">*</span>
              </span>
            </template>
            <template #default="scope">
              <el-input
                v-model="scope.row.sundryFeeTypeDesc"
                placeholder
                clearable
                readonly
                v-if="scope.row.paymentType === 3 || scope.row.paymentType === 5"
                @click="openIncidentaltDialog(scope.row, scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="结算金额" prop="expectAmount" class-name="no-pd" align="center" show-overflow-tooltip width="110">
            <template #default="scope">
              {{ scope.row.expectAmountDesc }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="applyPayAmount" class-name="no-pd" show-overflow-tooltip width="120">
            <template #header>
              <span>
                申请付款金额
                <span class="icon-must">*</span>
              </span>
            </template>
            <template #default="scope">
              <el-input
                v-model="scope.row.applyPayAmount"
                clearable
                :maxlength="10"
                @blur="validateApplyPaymentAmount($event, scope, 'applyPayAmount')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="凭据文件" prop="fileUrl" align="center" class-name="no-pd" width="95">
            <template #default="scope">
              <div class="fileUrl-box">
                <tableFileContent
                  v-if="load"
                  :rowIndex="scope.$index"
                  :fileUrlsArray="scope.row.attachmentDtoList"
                  key-name="fmFileName"
                  key-url="fmFileUrl"
                  key-id="fmFileId"
                  @getFileUrls="getFileUrls"
                  :readOnly="(scope.row.paymentType === 2 && scope.row.isNeedJr === true) || scope.row.paymentType === 3"
                ></tableFileContent>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="center" class-name="no-pd" show-overflow-tooltip>
            <template #default="scope">
              <el-input v-model="scope.row.remark" :maxlength="100" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column width="50" class-name="no-pd" align="center">
            <template #header>
              <span class="el-icon-circle-plus" style="font-size: 18px" @click="addOrder"></span>
            </template>
            <template #default="scope">
              <span class="list-del" @click="deleteOrder(scope.$index)">
                <img src="../../../../assets/images/list-del.png" alt />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--step2-->
    <Settlement v-if="showType === 2" @cancel="showType = 1" :orderId="orderId" @checked="getSettlementData" :rowIndex="settlementRowIndex"></Settlement>
    <Incidental
      v-if="showType === 3"
      @cancel="showType = 1"
      :orderId="orderId"
      :paymentType="paymentType"
      @checked="getIncidentalData"
      :rowIndex="incidentalRowIndex"
    ></Incidental>
    <Form2
      v-if="showType === 4"
      :formType="formType"
      @formdata="getSecondData"
      @cancel="submitCallBack"
      :orderTableData="formData.paymentOrderDtoList"
      :payeeType="formData.payeeType"
      :stepData="formData"
    ></Form2>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import Settlement from './components/settlement.vue';
import Incidental from './components/incidental.vue';
import Form2 from './components/form2.vue';
import { store } from '@/store';
export default {
  components: {
    tableFileContent,
    Settlement,
    Incidental,
    Form2,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
    editFormId: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
    showData: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      load: true,
      filterOption: {
        payment_type: store.state.dict.options.payment_type,

        payee_type_extend: store.state.dict.options.payee_type_extend,
      },
      showType: 1, // 1,申请付款1， 2，选择结算单， 3，选择杂费，4申请付款2
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .xls, .xlsx',
      titleImage: '', // 图片标题
      fileNameArr: [], // 文件名称
      fileUrlArr: [], // 图片地址
      fileUrlsArray: [],
      contractOptionIndex: 0, // 销售合同索引
      formData: {
        // 绑定表单数据
        payeeType: null, // 收款方类型
        operateType: null, // 操作类型：1.下一步 2.申请付款 3.编辑 4.取消 5.审核
        paymentOrderDtoList: [
          {
            orderId: null, // 订单号
            businessContractNo: null, // 销售合同号
            ContractNoOption: null, // 销售合同号Option
            ContractNoOptionObj: {}, // 该选项下值
            paymentType: null, // 付款类型
            paymentTypeDesc: null, // 付款类型描述
            isNeedJr: false, // 是否使用金融服务
            buyStmtNo: null, // 结算单号
            buyStmtNoData: {}, // 结算单号对象
            sundryFeeType: null, // 杂费类型
            sundryFeeTypeDesc: null, // 杂费类型展示
            sundryFeeTypeData: {}, // 杂费类型返回对象
            expectAmount: null, // 结算金额
            expectAmountDesc: null, // 结算金额
            applyPayAmount: null, // 申请付款金额
            remark: null, // 备注
            fundingId: null,
            customerId: null,
            attachmentDtoList: null,
            // contractAttachmentDto: {
            //   // 采购合同文件
            //   fmFileId: null,
            //   fmFileName: null,
            //   fmFileUrl: null,
            // },
            attachmentDtos: [], //采购合同文件
          },
        ],
        accountName: null, // 收款方名称
        accountNo: null, // 收款方账号
        bankName: null, // 开户银行名称
        branchName: null, // 开户行支行名称
        addressCity: null, // 开户行支行名称
      },
      rules: {
        // 检验规则
        payeeType: [{ required: true, message: '请选择收款方类型', trigger: 'change' }],
      },
      settlementRowIndex: null, // 当前打开的是哪行结算单号
      orderId: null, // 结算单号
      paymentType: null,
      incidentalRowIndex: null, // 当前打开的是哪行杂费
    };
  },
  computed: {
    readOnly() {
      return this.formType > 2; // 新增 编辑允许修改
    },
    showOrderListSummary() {
      return this.formData.paymentOrderDtoList.length > 1;
    },
  },
  created() {
    if (this.formType === 2) {
      this.initEditData(this.editFormId);
    }
    this.getContractNoOption('');
  },
  methods: {
    changePayeeType() {
      // 清除form1数据
      this.formData.accountName = null;
      this.formData.accountNo = null;
      this.formData.bankName = null;
      this.formData.branchName = null;
      this.formData.addressCity = null;
      // 清除form数据
    },
    getSecondData(data) {
      this.formData = Object.assign({}, this.formData, data);
    },
    viewOnline(name, url) {
      this.$showFiles(name, url);
    },
    initEditData(id) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.ajax({
        type: 'GET',
        url: 'malicrm/payment/paymentDetail/' + id,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            that.formData = Object.assign({}, that.formData, res.result);
            that.formData.paymentOrderDtoList = res.result.erpPaymentOrderVos.map((item) => {
              const obj = item;
              obj.ContractNoOption = [];
              obj.ContractNoOptionObj = {};
              obj.attachmentDto = {
                fmFileId: null,
                fmFileName: item.attachmentFileName,
                fmFileUrl: item.attachmentFileUrl,
              };
              obj.buyStmtNoData = {};
              obj.contractAttachmentDto = {
                fmFileId: null,
                fmFileName: item.contractFileName,
                fmFileUrl: item.contractFileUrl,
              };
              obj.sundryFeeTypeData = {};
              if (obj.fileVoList && obj.fileVoList.length > 0) {
                const attachmentDtoList = [];
                obj.fileVoList.forEach((file) => {
                  const objData = {
                    fmFileId: file.orderArapFileId,
                    fmFileName: file.orderArapFileName,
                    fmFileUrl: file.orderArapFileUrl,
                  };
                  attachmentDtoList.push(objData);
                });
                obj.attachmentDtoList = attachmentDtoList;
              } else {
                obj.attachmentDtoList = null;
              }
              return obj;
            });
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    submitCallBack(val) {
      if (val) {
        this.$emit('cancel', val);
      }
      this.showType = 1;
    },
    // 获取杂费金额弹窗 回调
    getIncidentalData(data, index) {
      const item = this.formData.paymentOrderDtoList;
      this.formData.paymentOrderDtoList[index].sundryFeeType = data.feeType;
      this.formData.paymentOrderDtoList[index].sundryFeeTypeDesc = data.feeTypeDesc;
      this.formData.paymentOrderDtoList[index].sundryFeeTypeData = data;
      if (this.formData.paymentOrderDtoList[index].paymentType === 3 || this.formData.paymentOrderDtoList[index].paymentType === 5) {
        // 如果付款类型为杂费，结算金额为已选中杂费的金额（人民币）
        this.formData.paymentOrderDtoList[index].expectAmount = data.amountRmb;
        this.formData.paymentOrderDtoList[index].expectAmountDesc = data.amountRmbDesc;
        if (data.fileVoList && data.fileVoList.length > 0) {
          const attachmentDtoList = [];
          data.fileVoList.forEach((file) => {
            const objData = {
              fmFileId: file.orderArapFileId,
              fmFileName: file.orderArapFileName,
              fmFileUrl: file.orderArapFileUrl,
            };
            attachmentDtoList.push(objData);
          });
          this.formData.paymentOrderDtoList[index].attachmentDtoList = attachmentDtoList;
        } else {
          this.formData.paymentOrderDtoList[index].attachmentDtoList = null;
        }

        // 如果付款类型为货款，凭据文件关联显示应付项目里结算单对应的文件
        item[index].orderArapDetailId = data.orderArapDetailId;
      }

      this.showType = 1;
    },
    // 获取结算金额弹窗 回调
    getSettlementData(data, index) {
      const item = this.formData.paymentOrderDtoList;
      this.formData.paymentOrderDtoList[index].buyStmtNo = data.apBuyStmtNo; // 结算单号;
      this.formData.paymentOrderDtoList[index].buyStmtNoData = data;
      if (this.formData.paymentOrderDtoList[index].paymentType === 2) {
        // 如果付款类型为货款，结算金额为已选中的结算单号的提货款
        this.formData.paymentOrderDtoList[index].expectAmount = data.apPickupAmount;
        this.formData.paymentOrderDtoList[index].expectAmountDesc = data.apPickupAmountDesc;

        if (data.fileVoList && data.fileVoList.length > 0) {
          const attachmentDtoList = [];
          data.fileVoList.forEach((file) => {
            const objData = {
              fmFileId: file.orderArapFileId,
              fmFileName: file.orderArapFileName,
              fmFileUrl: file.orderArapFileUrl,
            };
            attachmentDtoList.push(objData);
          });
          this.formData.paymentOrderDtoList[index].attachmentDtoList = attachmentDtoList;
        } else {
          this.formData.paymentOrderDtoList[index].attachmentDtoList = null;
        }
        item[index].orderArapDetailId = data.id;
      }

      this.showType = 1;
    },

    getFileUrls(params) {
      const { data, index } = params;
      this.load = false;
      const item = this.formData.paymentOrderDtoList[index];
      if (data.length > 0) {
        item.attachmentDtoList = data;
      } else {
        item.attachmentDtoList = null;
      }
      this.load = true;
    },
    // 销售合同号选择事件
    contractNoChange(val, index) {
      const item = this.formData.paymentOrderDtoList[index];
      const oindex = item.ContractNoOption.findIndex((items) => {
        return items.businessContractNo === val;
      });
      item.ContractNoOptionObj = item.ContractNoOption[oindex];
      // 销售合同号里 获取订单号
      if (this.formData.paymentOrderDtoList[index].ContractNoOptionObj && item.ContractNoOptionObj.orderId) {
        item.orderId = item.ContractNoOptionObj.orderId;
        item.fundingId = item.ContractNoOptionObj.fundingId;
        item.customerId = item.ContractNoOptionObj.customerId;
        item.isNeedJr = item.ContractNoOptionObj.isNeedJr;
        item.principalName = item.ContractNoOptionObj.principalName;
      } else {
        item.orderId = null;
        item.fundingId = null;
        item.customerId = null;
        item.principalName = null;
        item.isNeedJr = false;
      }
      // 采购合同文件
      // const fileObj = {};
      if (this.formData.paymentOrderDtoList[index].ContractNoOptionObj && item.ContractNoOption.length >= 1) {
        // fileObj.fmFileId = null;
        // fileObj.fmFileName = item.ContractNoOptionObj.name;
        // fileObj.fmFileUrl = item.ContractNoOptionObj.url;
        // item.contractAttachmentDto = fileObj;
        this.formData.paymentOrderDtoList[index].attachmentDtos = item.ContractNoOptionObj.attachmentDtos;
      } else {
        // fileObj.fmFileId = null;
        // fileObj.fmFileName = null;
        // fileObj.fmFileUrl = null;
        // item.contractAttachmentDto = fileObj;
        item.ContractNoOptionObj.attachmentDtos = [];
        this.formData.paymentOrderDtoList[index].attachmentDtos = [];
      }
      // 清空数据
      this.resetRowData(index);
    },
    // 清空数据
    resetRowData(index) {
      // 重置付款类型 paymentType
      this.formData.paymentOrderDtoList[index].paymentType = null;
      // 重置结算金额 expectAmount
      this.formData.paymentOrderDtoList[index].expectAmount = null;
      this.formData.paymentOrderDtoList[index].expectAmountDesc = null;
      // 重置付款类型 paymentType
      this.formData.paymentOrderDtoList[index].paymentType = null;
      this.formData.paymentOrderDtoList[index].paymentTypeDesc = null;
      // 重置结算单号 buyStmtNo
      this.formData.paymentOrderDtoList[index].buyStmtNo = null;
      this.formData.paymentOrderDtoList[index].buyStmtNoData = {};
      // 重置杂费类型 sundryFeeType
      this.formData.paymentOrderDtoList[index].sundryFeeType = null;
      this.formData.paymentOrderDtoList[index].sundryFeeTypeDesc = null;
      this.formData.paymentOrderDtoList[index].sundryFeeTypeData = {};
      // 重置结算金额
      this.formData.paymentOrderDtoList[index].buyStmtNo = null;
      this.formData.paymentOrderDtoList[index].buyStmtNoData = {};
      // 重置凭据文件
      // this.formData.paymentOrderDtoList[index].attachmentDto.fmFileId = null;
      // this.formData.paymentOrderDtoList[index].attachmentDto.fmFileName = null;
      // this.formData.paymentOrderDtoList[index].attachmentDto.fmFileUrl = null;
      // 重置申请付款金额
      this.formData.paymentOrderDtoList[index].applyPayAmount = null;
      // 重置备注
      this.formData.paymentOrderDtoList[index].remark = null;
    },
    // 获取结算金额
    getSettlementAmount(index) {
      const item = this.formData.paymentOrderDtoList[index];
      const type = item.paymentType;
      const orderId = item.orderId;
      const isNeedJr = item.isNeedJr;
      const category = item.ContractNoOptionObj.category;
      if (type === 1) {
        if (category === 1) {
          // 付款类型为保证金(期货)，结算金额为应付项目里的预付定金
          this.ajax({
            type: 'POST',
            url: 'malicrm/payment/getExpectAmountByOrderId',
            data: { orderId: orderId, type: 1 },
            success: (res) => {
              if (res.code === 200) {
                // 结算金额 expectAmount
                if (res.result) {
                  if (type === 1) {
                    item.expectAmount = res.result.expectAmount; // todo0310
                    item.expectAmountDesc = res.result.expectAmountDesc;
                  } else {
                    // todo0310
                  }
                } else {
                  item.expectAmount = null;
                  item.expectAmountDesc = null;
                }
              }
            },
          });
        } else {
          // 付款类型为保证金(现货)，结算金额为应付项目里的预付定金
          this.ajax({
            type: 'GET',
            url: `malicrm/order/queryOrderAmountInfoById/${orderId}`,
            success: (res) => {
              if (res.code === 200) {
                // 结算金额 expectAmount
                if (res.result) {
                  item.expectAmount = res.result.settleAmountPayment;
                  item.expectAmountDesc = res.result.settleAmountPaymentDesc;
                } else {
                  item.expectAmount = null;
                  item.expectAmountDesc = null;
                }
              }
            },
          });
        }
      }
      if (type === 6) {
        // 1.2.5保证金尾款外商
        this.ajax({
          type: 'GET',
          url: `malicrm/order/queryOrderAmountInfoById/${orderId}`,
          success: (res) => {
            if (res.code === 200) {
              // 结算金额 expectAmount
              if (res.result && category === 1) {
                // 有数据且为期货
                item.expectAmount = res.result.paymentFinalAmount;
                item.expectAmountDesc = res.result.paymentFinalAmountDesc;
              } else {
                item.expectAmount = null;
                item.expectAmountDesc = null;
              }
            }
          },
        });
      }
      if (type === 2 && isNeedJr === true) {
        // 付款类型为货款，结算金额为已选中的结算单号的提货款
        item.expectAmount = item.buyStmtNoData.apPickupAmount;
        item.expectAmountDesc = item.buyStmtNoData.apPickupAmountDesc;
      }
      if (type === 3 || type === 5) {
        // 付款类型为杂费，税费，结算金额为已选中杂费的金额；
        item.expectAmount = item.sundryFeeTypeData.amountRmb;
        item.expectAmountDesc = item.sundryFeeTypeData.amountRmbDesc;
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const noshow = [1, 2, 3, 4, 6, 7];
        if (noshow.includes(index)) {
          sums[index] = '';
          return;
        }
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number(prev) + curr;
            } else {
              return Number(prev);
            }
          }, 0);
          if (~String(sums[index]).indexOf('.')) {
            sums[index] = parseFloat(sums[index]).toFixed(2);
          }
          sums[index] = String(sums[index]).replace(reg, '$1,');
        } else {
          sums[index] = '';
        }
        if (sums[index] == '-0.00' || sums[index] == '-0.0' || sums[index] == '0.00' || sums[index] == '0.0') {
          sums[index] = 0;
        }
      });
      return sums;
    },
    deleteOrder(index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const data = that.formData.paymentOrderDtoList[index];
      let hasVal = true;
      if (
        data.businessContractNo ||
        data.paymentType ||
        data.buyStmtNo ||
        data.sundryFeeTypeDesc ||
        (data.applyPayAmount !== null && data.applyPayAmount !== '') ||
        data.remark ||
        data.attachmentDto.fmFileUrl
      ) {
        hasVal = false;
      }
      if (!hasVal) {
        this.$erpConfirm('确定删除吗？')
          .then(() => {
            that.formData.paymentOrderDtoList.splice(index, 1);
          })
          .catch(() => {
            return false;
          });
      } else {
        that.formData.paymentOrderDtoList.splice(index, 1);
      }
    },
    addOrder() {
      if (this.formData.paymentOrderDtoList.length > 200) {
        return;
      }
      const order = {
        orderId: null, // 订单号
        businessContractNo: null, // 销售合同号
        ContractNoOption: null, // 销售合同号Option
        ContractNoOptionObj: {}, // 该选项下值
        paymentType: null, // 付款类型
        paymentTypeDesc: null, // 付款类型描述
        buyStmtNo: null, // 结算单号
        buyStmtNoData: {}, // 结算单号对象
        sundryFeeType: null, // 杂费类型
        sundryFeeTypeDesc: null, // 杂费类型展示
        sundryFeeTypeData: {}, // 杂费类型返回对象
        expectAmount: null, // 结算金额
        expectAmountDesc: null, // 结算金额
        applyPayAmount: null, // 申请付款金额
        remark: null, // 申请付款金额
        fundingId: null,
        customerId: null,
        attachmentDto: {
          fmFileId: null,
          fmFileName: null,
          fmFileUrl: null,
        },
        contractAttachmentDto: {
          fmFileId: null,
          fmFileName: null,
          fmFileUrl: null,
        },
      };
      this.formData.paymentOrderDtoList.push(order);
      this.contractOptionIndex = this.formData.paymentOrderDtoList.length - 1;
      this.getContractNoOption('');
    },
    // 校验申请付款金额
    validateApplyPaymentAmount(e, scope, name) {
      let value = e.target.value;
      value = value.replace(/[^-\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, '-'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.indexOf('.') === 0) {
        value = '0' + value.substr(0, 3);
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 9999999.99) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 7);
        }
      }
      if (Number(value)) {
        scope.row[name] = value;
      } else {
        scope.row[name] = null;
      }
    },
    // 打开杂费类型 弹层
    openIncidentaltDialog(item, index) {
      this.showType = 3;
      this.incidentalRowIndex = index;
      this.orderId = this.formData.paymentOrderDtoList[index].orderId;
      this.paymentType = item.paymentType;
    },
    // 打开结算单 弹层
    openSettlementDialog(item, index) {
      this.showType = 2;
      this.orderId = this.formData.paymentOrderDtoList[index].orderId;
      this.settlementRowIndex = index;
    },
    // 付款类型选择事件
    changePayType(item, index) {
      // this.orderId = this.formData.paymentOrderDtoList[index].orderId;
      const opts = this.filterOption.payment_type;
      const label = opts.filter((v) => v.value === item.paymentType)[0].label;
      this.formData.paymentOrderDtoList[index].paymentTypeDesc = label;
      this.formData.paymentOrderDtoList[index].sundryFeeType = null;
      this.formData.paymentOrderDtoList[index].sundryFeeTypeDesc = null;
      this.formData.paymentOrderDtoList[index].sundryFeeTypeData = {};
      this.formData.paymentOrderDtoList[index].expectAmount = null;
      this.formData.paymentOrderDtoList[index].expectAmountDesc = null;
      this.formData.paymentOrderDtoList[index].buyStmtNo = null; // 结算单号;
      this.formData.paymentOrderDtoList[index].buyStmtNoData = {};
      this.formData.paymentOrderDtoList[index].expectAmount = null;
      this.formData.paymentOrderDtoList[index].expectAmountDesc = null;
      const fileObj = {};
      fileObj.fmFileId = null;
      fileObj.fmFileName = null;
      fileObj.fmFileUrl = null;
      // 如果付款类型为货款，凭据文件关联显示应付项目里结算单对应的文件
      this.formData.paymentOrderDtoList[index].attachmentDto = fileObj;

      // 获取结算金额
      this.getSettlementAmount(index);
    },
    /**
     * 销售合同下拉搜索
     * @param name
     */
    getContractNoOption(name) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const index = this.contractOptionIndex;
      // 获取销售合同号
      this.ajax({
        type: 'POST',
        url: '/malicrm/order/queryActiveOrder',
        loading: false,
        data: { businessContractNo: name },
        success: (res) => {
          if (res.code === 200) {
            that.formData.paymentOrderDtoList[index].ContractNoOption = res.result;
          }
        },
      });
    },
    blurContractNoOption(index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const option = this.formData.paymentOrderDtoList[index].ContractNoOption;
      if ((option && option.length <= 0) || !this.formData.paymentOrderDtoList[index].businessContractNo) {
        setTimeout(function () {
          that.getContractNoOption('');
        }, 500);
      }
    },
    focusContractNoOption(index) {
      this.contractOptionIndex = index;
    },
    nextStep() {
      const canNext = this.validOrderList();
      this.$refs.form.validate((valid) => {
        if (valid && canNext) {
          const orderValid = this.validateOrderType();
          if (orderValid) {
            if (this.isPayMoneny()) {
              this.nextStepSubmit();
            } else {
              this.errorTip('合计申请付款金额必须大于等于0');
            }
          }
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
    },
    // 检查每一行的申请付款金额，申请付款金额+“待审核”的申请付款金额+付款成功金额<=结算金额  2020/10/19 需
    isPayMoneny() {
      const data = this.formData.paymentOrderDtoList;
      let count = 0;
      count = data.reduce((total, item) => {
        let value = item.applyPayAmount;
        if (item.applyPayAmount === null || item.applyPayAmount === '') {
          value = 0;
        }
        return Number(total) + Number(value);
      }, 0);
      return Number(count) >= 0 || Number(count) > -0.000001;
    },
    validateOrderType() {
      if (this.formData.paymentOrderDtoList.length < 1) {
        this.errorTip('请填写所有带星号的必填项');
        return false;
      }
      let valid = true;
      if (this.formData.payeeType === 1) {
        const fundingId = this.formData.paymentOrderDtoList[0].fundingId;
        valid = this.formData.paymentOrderDtoList.every((item) => {
          return fundingId === item.fundingId;
        });
        if (!valid) {
          this.errorTip('批量付款的收款方必须相同');
        }
        return valid;
        // // 资金方
      }
      if (this.formData.payeeType === 4) {
        // 客户
        const customerId = this.formData.paymentOrderDtoList[0].customerId;
        valid = this.formData.paymentOrderDtoList.every((item) => {
          return customerId === item.customerId;
        });
        if (!valid) {
          this.errorTip('批量付款的收款方必须相同');
        }
        return valid;
      }
      return valid;
    },
    nextStepSubmit() {
      const data = this.formData;
      data.operateType = 1;
      this.ajax({
        type: 'POST',
        url: '/malicrm/payment/savePayment',
        data,
        success: (res) => {
          if (res.code === 200) {
            this.showType = 4;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    validOrderList() {
      let result = true;
      this.formData.paymentOrderDtoList.every((item) => {
        if (!item.businessContractNo) {
          result = false;
          return false;
        }
        if (!item.paymentType) {
          result = false;
          return false;
        }
        // 结算单号是否必填的判断（如果付款类型选择货款，且已使用金融服务，是必填）
        if (item.paymentType === 2 && item.isNeedJr === true && !item.buyStmtNo) {
          result = false;
          return false;
        }
        if (item.paymentType === 3 && !item.sundryFeeType) {
          result = false;
          return false;
        }
        if (item.applyPayAmount == null || item.applyPayAmount === '') {
          result = false;
          return false;
        }
        return result;
      });
      return Boolean(result);
    },
    back() {
      // 返回操作
      this.$emit('cancel', false, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.w68 {
  width: 68px;
}
.el-icon-circle-plus {
  cursor: pointer;
}
.financical-form {
  .erp-view__table {
    .icon-must {
      color: #f04737;
      font-size: 16px;
      position: relative;
      top: 2px;
    }
  }
  .financial-form-title {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    .title-tips {
      color: #f04737;
      font-size: 14px;
      .el-icon-warning {
        color: #f04737;
      }
    }
  }
  .claim_company {
    .erp-table__list {
      .fileUrl-box {
        width: 100%;
        text-align: center;
        height: 28px;
        overflow: hidden;
        line-height: 26px;
        display: flex;
        justify-content: center;
        .table-upload-view {
          color: #2878ff;
          display: inline-block;
          cursor: pointer;
          margin-right: 6px;
        }
      }
    }
  }
}
.financical-form .el-icon-circle-plus {
  position: relative;
  top: 2px;
}
.view-online {
  color: #2878ff;
  cursor: pointer;
}
.el-input--small .el-input__inner {
  line-height: 30px;
  height: 30px;
}
</style>
